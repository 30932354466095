<template>
  <TmPage data-title="Privacy" hide-header>
    <div class="card">
      <h1>Privacy Policy</h1>
      <p class="c3">
        Effective Date: December 5, 2019
      </p>
      <p><span class="c5">When this Privacy Policy Applies</span></p>
      <p>
        <span class="c0">
          This Privacy Policy applies to the use of https://staking.harmony.one/
          (the “App”) and the use and/or access of any of the products, services
          or content provided by or on behalf of Simple Rules Company (“SRC”) in
          connection with this Privacy Policy is linked or posted together
          referred to as the “Services”. Please read this Privacy Policy before
          using the Services because it tells you how we collect, use and
          disclose your personal information.
        </span>
      </p>
      <p>
        <span class="c0"
          >The Services provide software tools for users to access and manage
          various features and functions related to digital assets including
          tokens, cryptocurrencies, virtual currencies or any other digital
          assets you own (collectively, &ldquo;Digital Assets&rdquo;), including
          interfacing with certain third party sources for certain information
          relating to the Digital Assets.</span
        >
      </p>
      <p>
        <span
          >This Privacy Policy does not apply to (i) any SRC services that has
          its own privacy policy or a different privacy policy, in which case
          that privacy policy, not this privacy policy will apply and (ii) to
          information that is collected offline or by any third-party website or
          services that you access through the Services, who may have separate
          privacy policies. &nbsp;</span
        ><span class="c11"
          >These third-party privacy policies will be available on the
          applicable third-party&rsquo;s website and you should review the
          third-party privacy policies to be sure you understand how
          third-parties are collecting, using and disclosing and using your
          personal information when you use or access Services or access other
          third-party services. &nbsp;We are not responsible for
          third-parties&rsquo; collection or use of personal information.</span
        >
      </p>
      <p>
        <span class="c0"
          >In this Privacy Policy, to &ldquo;process&rdquo; information means to
          perform any operation on the information, whether or not by automated
          means, such as collection, recording, organizing, storing, adapting,
          use, disclosure, combining, erasing or destroying.</span
        >
      </p>
      <p><span class="c4">This Privacy Policy describes:</span></p>
      <ul class="c6 lst-kix_418cef89mfk-0 start">
        <li class="c1">
          <span class="c0"
            >What information we collect, how we collect it, and why</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >How we use that information and with whom we share it</span
          >
        </li>
        <li class="c1">
          <span class="c0">How you can access and update that information</span>
        </li>
        <li class="c1">
          <span class="c0"
            >The choices you can make about how we collect, use, and share your
            information</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >How we protect the information we store about you</span
          >
        </li>
      </ul>
      <p>
        If you do not want us to collect, store, use or share your information
        in the ways described in this Privacy Policy, please do not access the
        App or otherwise access or use the Services. &nbsp;By accessing the App
        and using or accessing the Services you consent to this Privacy Policy.
      </p>
      <p>
        <span class="c0"
          >This policy is current as of the date set forth above. However,
          please note that this policy may be amended from time to time to
          reflect changes and additions to the privacy policy. Please check back
          for the most current version before relying on any of the provisions
          in this privacy policy.</span
        >
      </p>
      <p>
        <span class="c5">Information We Collect and How We Collect It</span>
      </p>
      <p>
        <span class="c0"
          >We collect certain information in the following ways:</span
        >
      </p>
      <p><span class="c4">Information You Give Us Directly</span></p>
      <p>
        <span class="c0"
          >We collect some information from you directly. This information may
          be provided via an online form or when you talk to a member of our
          team. For example, you may provide:</span
        >
      </p>
      <ul class="c6 lst-kix_d6befgnnes86-0 start">
        <li class="c1">
          <span
            >Your name, age or birthday, email address, username, address,
            password, telephone number</span
          ><span class="c0">;</span>
        </li>
        <li class="c1">
          <span class="c0">Your current employer and place of work;</span>
        </li>
        <li class="c1">
          <span class="c0">Account preferences;</span>
        </li>
        <li class="c1">
          <span class="c0">Security questions and answers; &nbsp;</span>
        </li>
        <li class="c1">
          <span class="c0"
            >Information linking your account to third-party service providers,
            including information necessary to track the value of your Digital
            Assets if you sign up for a Digital Wallet (as described in our
            Terms of Service);</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Information linking your account to your personal accounts such as
            Google and Github</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Demographic details and information in response to questions or
            surveys about our products or information to enter a contest,
            promotion or sweepstakes;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >The contact information you give in connection with any customer
            service query, and any communications, information, data or history
            in relation to any query. &nbsp;We may monitor or record
            interactions with our customer service team for training purposes
            and to ensure a high level of service;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Information, insights or responses to questions provided if you
            respond to any of our surveys. &nbsp;You may choose whether to
            participate in any survey; and</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Your mobile telephone number, if you choose to sign up and receive
            SMS messages or notifications.</span
          >
        </li>
      </ul>
      <p>
        <span class="c0"
          >Certain information that you provide us directly will become public
          information if you elect to provide it to us, for example we may use
          customer reviews for marketing purposes and we may aggregate
          demographic information about individuals who use the Services and
          disclose this aggregate information.</span
        >
      </p>
      <p>
        <span class="c4"
          >Information We Collect Automatically When You Use the App or access
          or use the Services.
        </span>
      </p>
      <p>
        <span class="c0"
          >We collect other information about the Services you use and how you
          use them, including when and how often you use the Services, what
          features you used, how you view and interact with our other content
          and the devices you use to access the Services. &nbsp;This information
          is collected automatically using Cookies and Similar Technology as
          described below. &nbsp;This information includes;
        </span>
      </p>
      <ul class="c6 lst-kix_8bel9shkt3ps-0 start">
        <li class="c1">
          <span class="c0"
            >IP address (including country), which will provide general
            information about your geographic location;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >The type of computer or mobile device you are using (including your
            device&rsquo;s unique identifier);</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Device-specific settings and other hardware settings, including
            operating system and other software settings, browser type, browser
            language, time zones, and certain cookies or other technologies that
            may associate your device information with your SRC account;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Browser information including your browser type and the language
            you prefer;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Referring and exit pages, including landing pages and pages
            viewed;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Information on your wireless and mobile network connection, like
            your service provider and signal strength;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Information about your use of the Services, including the date and
            time you log in, search queries, the date and time of requests and
            any referral URLs;</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Device event information, including crash reports, request and
            referral URL&rsquo;s and system activity details (e.g., whether the
            error occurred on the App or you lost internet access); and</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >We collect other information with your consent.</span
          >
        </li>
      </ul>
      <p>
        <span class="c0"
          >We also may collect and store information locally on your device
          using mechanisms like cookies, browser web storage and application
          data caches.</span
        >
      </p>
      <p>
        <span class="c8 c11">Information we get from Third-Parties.</span
        ><span class="c7">&nbsp; </span>
      </p>
      <p>
        <span class="c0"
          >Third parties that assist us with our business operations also may
          collect information through the Services.</span
        >
      </p>
      <p>
        <span
          >We engage service providers to collect information to help us </span
        ><span>secure the Services, detect and prevent fraud</span
        ><span class="c0"
          >, provide a better user experience and ensure the Services are
          working as expected. Some of this information is collected through
          Cookies and Similar Technologies as described below.</span
        >
      </p>
      <p>
        <span class="c0"
          >We do not currently have advertising in the Services, we may have
          advertising in the future in which case we may receive information
          about you from our advertising partners, such as when advertising
          campaigns are published through the Services.</span
        >
      </p>
      <p>
        <span class="c0"
          >We may also receive information about you ff you elect to connect to
          a third party application through the Services. &nbsp;The information
          we receive depends on the third-party application, your privacy
          settings in that application. &nbsp;If you connect the Services to a
          third-party application, you should also read that third-party
          application&rsquo;s terms of services and privacy policy.</span
        >
      </p>
      <p>
        <span class="c5"
          >Information we get from Cookies and Similar Technologies.
          &nbsp;</span
        >
      </p>
      <p>
        <span class="c0"
          >We and service providers acting on our behalf store log files and use
          tracking technologies such as:</span
        >
      </p>
      <ul class="c6 lst-kix_8jq553b4xpm4-0 start">
        <li class="c1">
          <span
            >Cookies are bits of electronic information that are sent to or
            transferred from your web browser or hard drive to customize a
            person&#39;s use of a product or online site, keep records of a
            user&#39;s access to an online site or product, or store information
            needed by the user on a regular basis. &nbsp;A cookie typically
            contains the name of the domain (internet location) from which the
            cookie originated, the &ldquo;lifetime&rdquo; of a cookie (or when
            it expires) and a randomly generated unique number or similar
            identifier. &nbsp;A cookie may also contain information about your
            computer, such as user settings, browser history and activities
            conducted while using the Services. &nbsp;A web beacon (also called
            a pixel tag or clear GIF) is a piece of computer code that enables
            us to monitor user activity and website traffic. &nbsp;To learn more
            about cookies and web beacons, visit www.allaboutcookies.org</span
          >
        </li>
        <li class="c1">
          <span
            >Cookies and similar data collection technology help us improve your
            experience of the Services. In the future we may use Cookies to
            personalize and improve your experience of the Services by, for
            example, storing your password so that you do not have to re-enter
            each time you use the Services, helping us analyze technical and
            navigational information about the Services to better understand how
            our products are being used, personalizing and tailoring ads to your
            interest and detecting fraud.</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >We may link information from cookies with other personal
            information we hold about you.
          </span>
        </li>
        <li class="c1">
          <span>The Services use the following Cookies:</span>
        </li>
      </ul>
      <ul class="c6 lst-kix_8jq553b4xpm4-1 start">
        <li class="c13">
          <span
            >Cookies that are strictly necessary for the operation of the
            Services. </span
          ><span class="c0">&nbsp; </span>
        </li>
        <li class="c13">
          <span class="c0"
            >Analytical/Performance cookies, which allow us to recognize and
            count the number of visitors, learn how visitors navigate the
            Service and to help us improve the way the Services function.
            &nbsp;For example, When you sign in to the App, we store your user
            id and a combination of your IP address and time of login as a
            cookie on your hard drive. This cookie allows you to move from page
            to page without having to sign in again on each page.
          </span>
        </li>
        <li class="c13">
          <span class="c0"
            >Functionality Cookies, which recognize you when you return to the
            Services.</span
          >
        </li>
        <li class="c13">
          <span class="c0"
            >Cookies tied to Google Analytics, which is a Google service that
            collects information about use of the Services. Google Analytics
            does not identify individual services.</span
          >
        </li>
        <li class="c13">
          <span class="c0">
            Cookies tied to Appzi and similar services that provide information
            and analytics on the the App&rsquo;s performance and user
            experience.
          </span>
        </li>
      </ul>
      <p><span class="c5">Controlling Cookies</span></p>
      <p>
        <span class="c0"
          >Some web browsers (including some mobile web browsers) provide
          settings that allow you to control or reject cookies or to alert you
          when a cookie is placed on your computer, tablet or mobile device.
          &nbsp;You also may be able to reject mobile device identifiers by
          activating the appropriate setting on your mobile device. &nbsp;You
          are not required to accept cookies or mobile device identifiers, but
          if you elect to block or reject them you may not have access to all of
          the Services.</span
        >
      </p>
      <p><span class="c5">Our Policy on Do Not Track Signals</span></p>
      <p>
        <span
          >Some web browsers (including Safari, Internet Explorer, Firefox and
          Chrome) incorporate a &ldquo;Do Not Track&rdquo; (&ldquo;DNT&rdquo;)
          or similar feature that signals to websites that a user does not want
          to have his or her online activity and behavior tracked. &nbsp;If a
          website that response to a particular DNT signal, the browser can
          block that website from collecting certain personal information about
          the browser&rsquo;s use. &nbsp;Not all browsers offer a DNT option and
          DNT signals are not yet uniform. &nbsp;For this reason, many websites,
          including our website and App do not respond to DNT signals. &nbsp;To
          learn more about DNT signals go visit allaboutdnt.com. &nbsp;</span
        ><span
          >You may opt out of certain types of tracking, including certain
          analytics and tailored advertising by changing your cookie
          settings.</span
        >
      </p>
      <p><span class="c5">Third Party Advertising</span></p>
      <p>
        <span class="c0"
          >We do not currently have third-party advertising on the App; however,
          we may have advertising in the future.</span
        >
      </p>
      <p>
        <span class="c0"
          >Moreover, if you access third-party websites through the App or the
          Services you may encounter third-party advertising. &nbsp;If you
          click-through to a third-party website, you are no longer on a site
          hosted by SRC and activity on the third-party site will be governed by
          the third-party&rsquo;s privacy policy. &nbsp;You should review this
          privacy policy to understand what information will be collected and
          what technology the third-party uses to collect this information.
          &nbsp;</span
        >
      </p>
      <p><span class="c5">Third Party Information Sources</span></p>
      <p>
        <span class="c0"
          >We may supplement the information described above with information we
          obtain from other sources, including from both online and offline data
          providers. &nbsp;We use information from these companies primarily to
          deliver optimized content to you and to understand your approximate
          geolocation via your IP address to customize certain services to your
          location.</span
        >
      </p>
      <p><span class="c5">Storage of Information on Your Device</span></p>
      <p>
        <span class="c0"
          >We store certain information that is required for the use of the
          Services on your hard drive or device when you download the App.
          &nbsp;This information includes:</span
        >
      </p>
      <ul class="c6 lst-kix_1036oliedv70-0 start">
        <li class="c1">
          <span class="c0"
            >Information regarding your Digital Wallet, your internet connection
            and our software, which is uploaded to the server when you access
            your account in connection with your use of the Services</span
          >
        </li>
        <li class="c1">
          <span
            >Meta Data files, which optimize the speed and performance of the
            Services</span
          >
        </li>
        <li class="c1">
          <span class="c0">Cookies</span>
        </li>
        <li class="c1">
          <span class="c0"
            >Plug-ins necessary for the App to operate on the web browser of
            your choice, such as a Chrome plug-in</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >When the App is available on mobile devices, native apps will be
            stored<br
          /></span>
        </li>
      </ul>
      <p><span class="c5">How We Process The Information We Collect</span></p>
      <p>
        <span class="c0"
          >We process your personal and non-personal information, both
          individually and aggregated, in the following ways:</span
        >
      </p>
      <p>
        <span class="c4"
          >To administer your account and provide the Services to you:</span
        >
      </p>
      <ul class="c6 lst-kix_keb84vmiq8ga-0 start">
        <li class="c1">
          <span class="c0"
            >Set up and maintain your account and Digital Wallet</span
          >
        </li>
        <li class="c1">
          <span class="c0">Communicate with you</span>
        </li>
        <li class="c1">
          <span class="c0"
            >To respond to your questions and to try to resolve any problems
            with the Services</span
          >
        </li>
        <li class="c1">
          <span class="c0">To complete transactions you initiate</span>
        </li>
        <li class="c1">
          <span class="c0"
            >Identify, fix and troubleshoot bugs and service errors;</span
          >
        </li>
        <li class="c1">
          <span class="c0">Provide software updates;</span>
        </li>
        <li class="c1">
          <span
            >Enable third-party service providers to deliver information and
            updates</span
          >
        </li>
      </ul>
      <p>
        <span class="c4">To improve the Services and develop new ones:</span>
      </p>
      <ul class="c6 lst-kix_k6vcdb8m5d6a-0 start">
        <li class="c1">
          <span class="c0">Administer surveys</span>
        </li>
        <li class="c1">
          <span class="c0"
            >Analyze and understand our visitors and users, improve our Services
            (including our user interface) and optimize content and/or feature
            selection, recommendations and delivery</span
          >
        </li>
        <li class="c1">
          <span class="c0">Develop new features and services</span>
        </li>
        <li class="c1">
          <span class="c0">Measure the health of the Services</span>
        </li>
        <li class="c1">
          <span class="c0">Research</span>
        </li>
      </ul>
      <p>
        <span class="c4"
          >To prevent, detect and fight fraud or other illegal or unauthorized
          activities</span
        >
      </p>
      <ul class="c6 lst-kix_33lhdyht0tp3-0 start">
        <li class="c1">
          <span class="c0">Address ongoing or alleged misbehavior</span>
        </li>
        <li class="c1">
          <span class="c0"
            >Research and analyze data to better understand and design
            countermeasures against these activities</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Retain data related to fraudulent activities to prevent
            recurrences</span
          >
        </li>
      </ul>
      <p><span class="c4">To enable legal compliance</span></p>
      <ul class="c6 lst-kix_dzat59b1n2vo-0 start">
        <li class="c1">
          <span class="c0">Comply with legal requirements</span>
        </li>
        <li class="c1">
          <span class="c0">Enforce or exercise our rights</span>
        </li>
      </ul>
      <p>
        <span class="c4"
          >To personalize our communication with you, including to:</span
        >
      </p>
      <ul class="c6 lst-kix_w546r7h9m7fv-0 start">
        <li class="c1">
          <span class="c0">Respond to your requests and inquiries</span>
        </li>
        <li class="c1">
          <span class="c0">Communicate with you regarding the Services</span>
        </li>
        <li class="c1">
          <span class="c0">Make recommendations to you</span>
        </li>
      </ul>
      <p>
        <span class="c4"
          >To process your information, as described above, we rely on the
          following:</span
        >
      </p>
      <ul class="c6 lst-kix_9kwoblfgnzxl-0 start">
        <li class="c1">
          <span class="c0"
            >Provide the Services to you: &nbsp;We process your information to
            perform the contract (Terms of Service) that you have with us</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Legitimate interests: &nbsp;We use your information for legitimate
            business interests when permitted by law. &nbsp;For example, we
            analyze users&rsquo; behavior on the Services to improve them and
            for administrative, fraud detection and legal purposes. &nbsp;In the
            future, we may use your information for direct marketing
            purposes.</span
          >
        </li>
        <li class="c1">
          <span
            >Consent: &nbsp;We may ask for your consent to use your information
            for certain specific reasons. &nbsp;</span
          ><span
            >You may withdraw your consent at any time by contacting us using
            the contact information provided at the end of this Privacy
            Policy.</span
          >
        </li>
      </ul>
      <p><span class="c5">How We Share Your Information</span></p>
      <p>
        <span class="c0"
          >We may share information collected through the Services as
          follows:</span
        >
      </p>
      <p><span class="c4">Third Party Service Providers</span></p>
      <p>
        <span class="c0"
          >We share information with our third-party service providers that help
          us operate the Services, such as by providing customer service, IT
          services, product support or testing, and strengthening security
          measures. In the course of providing such services these service
          providers may have access to your personal information. &nbsp;We do
          not authorize service providers to use your personal information
          except in connection with providing services.</span
        >
      </p>
      <p><span class="c4">Protection of SRC and Others</span></p>
      <p>
        <span class="c0"
          >We and our third party service providers may disclose and otherwise
          use your personal information where we or they reasonably believe such
          disclosure is needed to:</span
        >
      </p>
      <ul class="c6 lst-kix_t4g0skrj36h3-0 start">
        <li class="c1">
          <span class="c0">Satisfy any law or regulation </span>
        </li>
        <li class="c1">
          <span class="c0"
            >Respond to a lawful request from a public authority, including law
            enforcement or national security authorities, or legal process</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Enforce applicable terms of use, including applicable violations
            thereof</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Detect, prevent or otherwise address illegal or suspected illegal
            activities (including to report such activity), security or
            technical issues</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Protect against harm to the health and/or safety of you, another
            person or the public generally</span
          >
        </li>
        <li class="c1">
          <span class="c0"
            >Protect the rights or property of SRC, its users or the public as
            required or permitted by law</span
          >
        </li>
      </ul>
      <p><span class="c4">Corporate Transactions/ Business Transfers</span></p>
      <p>
        <span class="c0"
          >In connection with any business transition (including a proposed
          transaction), like a merger or sale, acquisition by another company,
          reorganization, restructuring or transfer of assets, bankruptcy,
          dissolution or other change of ownership or control we may disclose or
          transfer all of your information, including personal
          information.</span
        >
      </p>
      <p><span class="c4">Other lawful disclosure</span></p>
      <p>
        <span class="c0"
          >We may share your information if (i) disclosure could mitigate
          SRC&rsquo;s liability in an actual or threatened lawsuit, (ii) as
          necessary to protect legal rights of our users, customers, business
          partners or other interested parties, (iii) to pursue available
          remedies or limit the damages, (iv) to enforce our agreements
          (including the Terms of Service) and (v) to respond to an
          emergency.</span
        >
      </p>
      <p><span class="c4">With your consent</span></p>
      <p>
        <span class="c0"
          >With your consent, we may share your information from the Services in
          ways not specifically described in this Privacy Policy.</span
        >
      </p>
      <p><span class="c8 c11">Aggregated and Anonymous Information</span></p>
      <p>
        <span class="c0"
          >We may process aggregated personal information about you (information
          about you and other users that is aggregated with information about
          other users that is not intended to specifically identify you) and
          anonymous data (aggregated or not) about you in order to analyze and
          produce statistics related to the habits, usage patterns, and
          demographics of users as a group or as individuals. &nbsp;We may share
          this information with third parties without limitation on such third
          parties&rsquo; use of the information. &nbsp;We may disclose or
          publish this data (aggregated or not) for industry analysis
          demographic profiling, marketing, analytics, advertising, and other
          business purposes.</span
        >
      </p>
      <p><span class="c5">Cross Border Data Transfers</span></p>
      <p>
        <span>[</span
        ><span
          >Sharing information sometimes involves cross-border transfers. Your
          personal information may therefore be transferred to, stored and
          processed in the United States and other countries where we and our
          partners and service providers operate. &nbsp;For example, if the
          Services are available to users in the European Economic Area
          (&ldquo;EEA&rdquo;), these users&rsquo; personal information is
          transferred to countries outside of the EEA to the US or other
          jurisdictions. &nbsp;Please be aware that these countries may have
          data protection laws that are more or less protective than the laws of
          the country where you live. &nbsp;We use safeguards approved by the
          European Commission to legitimize data transfers from the EEA to other
          countries.</span
        >
      </p>

      <p>
        <span class="c5">How to Access Manage and Update Your Information</span>
      </p>
      <p><span class="c4">Choice/Opt-out</span></p>
      <p>
        <span>When we begin promotional communication, w</span
        ><span class="c0"
          >e will give you the option to receive promotional email
          communications from us. Users will be able to opt-out of these
          communications. If you do not want to receive these non-transactional
          communications you will be able to opt-out of the communications by
          selecting &ldquo;unsubscribe&rdquo; on any email. &nbsp;Please note
          that once we receive your request, it may take an additional period of
          time for your opt-out to become effective. Your unsubscribe or email
          preference change will be processed promptly, and in no event longer
          than ten business days.</span
        >
      </p>
      <p>
        <span
          >If we begin sending promotional SMS messages, you will be able to
          opt-out of receiving SMS messages (for example text messages) by
          texting back STOP to the number we send the text from for that
          particular SMS message program. &nbsp;In some cases, we will include a
          preferences management page. &nbsp;When we receive an opt-out message
          from you for SMS messages, we may send a message confirming our
          receipt of your opt-out.</span
        >
      </p>
      <p>
        <span
          >When you install our App on a mobile device, you will be able to
          choose to receive push notifications, which are messages an app sends
          you on your mobile device when the app is not on. &nbsp;You will be
          able to turn off notifications by visiting your mobile device&rsquo;s
          &ldquo;settings&rdquo; page.</span
        >
      </p>
      <p><span class="c4">Opting out of Geolocation</span></p>
      <p>
        <span
          >If you would like to stop allowing us to access your geolocation
          data, you can stop making geolocation available to us on your mobile
          device by changing the settings on your mobile device. &nbsp;We may
          still be able to get some location information using your IP address
          and other information provided to us by third- parties.</span
        >
      </p>
      <p><span class="c4">Opting out of Cookie Tracking</span></p>
      <p>
        <span class="c0"
          >You can set your web browser to warn you about attempts to place
          cookies on your computer or limit the type of cookies you allow. If
          you disable cookies you may lose your ability to access some of the
          Services because cookies are necessary to track and enhance some of
          the Services.</span
        >
      </p>
      <p>
        <span class="c8"
          >Corrections, Updates and Removal of Personal Information</span
        >
      </p>
      <p>
        <span class="c0"
          >If you are concerned about the accuracy or legality of personal
          information we maintain or wish to review, access, supper, amend or
          correct it, would like your personal information removed from our
          records or otherwise deactivated, or would like to receive an
          electronic copy of your personal information of transmitting it to
          another company (if this right to data portability is provided to you
          by law) you can:</span
        >
      </p>
      <ul class="c6 lst-kix_lbc86pg78p90-0 start">
        <li class="c1">
          <span class="c0"
            >Change your settings on your device to either consent or withdraw
            consent to the collection of certain information. &nbsp;As discussed
            above, if you do this you may lose some or all functionality related
            to the Services</span
          >
        </li>
        <li class="c1">
          <span class="c0">Delete your account </span>
        </li>
        <li class="c1">
          <span class="c0"
            >Contact us at hello@harmony.one with your request.
          </span>
        </li>
      </ul>
      <p>
        <span class="c0"
          >In your request, please make clear what personal information you
          would like to have changed, whether you would like to have your
          personal information suppressed form our database, or other
          limitations you would like to put on the use of your personal
          information. &nbsp;For your protection, we will only fulfill requests
          for personal information associated with the email address associated
          with your account. &nbsp;We may need to verify your identity before
          fulfilling your request.
        </span>
      </p>
      <p>
        <span class="c0"
          >We will consider all such requests in accordance with applicable law.
          We may decline requests that are unreasonably repetitive, require
          disproportionate technical effort or expense, or jeopardize the
          privacy of others.
        </span>
      </p>
      <p>
        <span class="c0"
          >Please note that we often need to retain certain data for
          recordkeeping purposes and/or to complete any transaction that you
          began prior to requesting a change or deletion, our databases also may
          have residual data which we do not remove. &nbsp;We will not allow you
          to review certain data for legal, security or other reasons.</span
        >
      </p>
      <p>
        <span class="c0"
          >If at any time you believe that we have not adhered to this Privacy
          Policy, please let us know. &nbsp;We will use good faith efforts to
          determine and resolve the problem.</span
        >
      </p>
      <p>
        <span class="c0"
          >If you are an EEA resident, you have the right to lodge a complaint
          with the data protection authority hello@harmony.one about how we
          process your personal information,</span
        >
      </p>
      <p>
        <span class="c0"
          >If you are a California resident, California Civil Code Section
          1798.83 permits individual California residents to request certain
          information regarding our disclosure of personal information to third
          parties for their direct marketing purposes. &nbsp;To make such a
          request, please contact us at hello@harmony.one.</span
        >
      </p>
      <p><span class="c5">Children Privacy</span></p>
      <p>
        <span
          >The Services are not directed or intended for use by minors and we
          will not knowingly collect personal information from any person under
          the age of 13. &nbsp;</span
        ><span
          >Children under the age of 13 should not use the App or access or use
          the Services at any time</span
        ><span class="c0"
          >. Consistent with the requirements of the Children&rsquo;s Online
          Privacy Protection Act, if we learn that we have inadvertently
          gathered personal information from a child under 13 without his or her
          parent&rsquo;s or legal guardian&rsquo;s verified consent, we will
          take reasonable measures to promptly remove that information from our
          records and will use that information only to respond to that child
          (or his or her parent or legal guardian) to inform the child that he
          or she cannot use the App or access or use the Services. &nbsp;</span
        >
      </p>
      <p>
        <span class="c0"
          >Parents or legal guardians who are concerned about the transfer of
          personal information from their children may contact us at
          hello@harmony.one.</span
        >
      </p>
      <p>
        <span class="c5"
          >Privacy Policies of Linked Third-Party Services and Advertisers</span
        >
      </p>
      <p>
        <span class="c0"
          >The Services may include links to third party websites and services
          that are not operated by us. &nbsp;When you click these links, you
          will be directed away from the Services. &nbsp;External websites and
          companies with links to and from the Services may collect personal
          information about users when users visit their websites. A link to or
          from a third party website or service does not mean that we endorse
          it, or the quality of accuracy or information presented on it.
          &nbsp;If you decide to visit a third-party website or service, you are
          subject to its privacy practices and not ours. &nbsp;This Privacy
          Policy does not apply to any personal information that you provide to
          these other websites and services. &nbsp;We are not responsible for
          their privacy practices or the content of their third-party services.
          &nbsp;Please refer directly to these companies and websites regarding
          their privacy policies and how they use your information.</span
        >
      </p>
      <p>
        <span class="c5">Storage and Security of Personal Information</span>
      </p>
      <p>
        <span class="c0"
          >We take precautions intended to protect the information we collect
          and store, no website or internet transmission is completely secure.
          This means we cannot guarantee the security of your information
          transmitted to or through the Services and do not assume any
          responsibility for the unauthorized access to your information under
          our control. &nbsp;Any transmission is at your own risk and we expect
          that you will use appropriate security measures to protect your
          information.</span
        >
      </p>
      <p>
        <span class="c0"
          >You are responsible for maintaining the security of your account and
          your Digital Wallet, including the device on which your store your
          Digital Wallet. &nbsp;Access to the Services through your account is
          treated as authorized by you. Unauthorized access to
          password-protected or secure areas is prohibited and may lead to
          criminal prosecution. &nbsp;We may suspend your use of all or part of
          the Services without notice if we suspect or detect any breach of
          security.</span
        >
      </p>
      <p>
        <span
          >We keep your personal information in identifiable form only (i) as
          long as we need it for lawful purpose for which we collected it, or if
          longer, (ii) to comply with law, or (iii) to resolve disputes and to
          enforce agreements with you.</span
        >
      </p>
      <p><span class="c5">Changes to Our Privacy Policy</span></p>
      <p>
        <span
          >The effective date of this Privacy Policy is set forth at the top of
          this policy. &nbsp;Our privacy policy may change from time to time.
          &nbsp;We will post any changes on this page and, if the changes are
          material, we will tell you and other users of the change by placing a
          notice on the webpage or by sending you a notice to the email address
          we have on file for you prior to change becoming effective. &nbsp;You
          should periodically check the App and this privacy page for updates.
          &nbsp;</span
        ><span class="c8 c11"
          >Your continued use of the Services after the effective date of any
          new privacy policy constitutes acceptances of the amended privacy
          policy</span
        ><span class="c8">.</span
        ><span class="c0"
          >&nbsp; The amended privacy policy will supersede all prior privacy
          policies.</span
        >
      </p>
      <p><span class="c5">Contacting Us</span></p>
      <p>
        <span class="c0"
          >If you have any questions, comments or concerns regarding our Privacy
          Policy or practices, please contact us at hello@harmony.one.
        </span>
      </p>
    </div>
  </TmPage>
</template>

<script>
import TmPage from "common/TmPage"

export default {
  name: `page-privacy`,
  components: {
    TmPage
  }
}
</script>

<style scoped src="../../styles/content-page.css"></style>
